import React from 'react';
import Table from '../ui/Table';
import { soundOk, soundNotOk } from '../../helpers';
import moment from 'moment';
import { parse } from 'json2csv';
import { saveAs } from 'file-saver';
import { labelList } from '../../services';

const columns = [
  {
    Header: 'Lookup Label',
    accessor: 'label',
    Cell: ({ state: { handleInputChange, handleOnPaste }, row: { index, original }, }) => <input
      className="min-w-full"
      value={original.label || ''}
      name="label"
      onChange={e => handleInputChange(e, index)}
      onPaste={(e) => handleOnPaste(e, index, 0)}
    />,
  },
  {
    Header: 'Assign Pallet Marking',
    accessor: 'marking',
    Cell: ({ state: { handleInputChange, handleOnPaste }, row: { index, original }, }) => <input
      className="min-w-full"
      value={original.marking || ''}
      name="marking"
      onChange={e => handleInputChange(e, index)}
      onPaste={(e) => handleOnPaste(e, index, 1)}
    />,
  },
  {
    Header: 'Qty',
    accessor: 'qty',
    Cell: ({ state: { handleInputChange, handleOnPaste }, row: { index, original }, }) => <input
      className="min-w-full"
      value={original.qty || ''}
      name="qty"
      onChange={e => handleInputChange(e, index)}
      onPaste={(e) => handleOnPaste(e, index, 2)}
    />,
  },
  {
    Header: 'Received Qty',
    accessor: 'received_qty',
  },
  {
    Header: ({ state: { handleAddMapping } }) => <div className="cursor-pointer" onClick={handleAddMapping}>+</div>,
    accessor: 'add',
    Cell: ({ state: { handleRemoveMapping }, row: { index } }) => <div className="cursor-pointer text-center" onClick={(e) => handleRemoveMapping(e, index)}>-</div>,
  },
];

const Modal = ({ title, onClose, children }) => {
  return <div className="fixed w-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster">
    <div className="border shadow-lg modal-container bg-white w-6/12 md:max-w-11/12 mx-auto rounded-xl shadow-lg z-50 overflow-y-auto">
      <div className="modal-content py-4 text-left px-6">
        <div className="flex justify-between items-center pb-3">
          <p className="text-2xl font-bold text-gray-500">{title}</p>
          <div className="modal-close cursor-pointer z-50" onClick={onClose}>
            <svg className="fill-current text-gray-500" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
              viewBox="0 0 18 18">
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
              </path>
            </svg>
          </div>
        </div>
        {children}
      </div>
    </div>
  </div>
}

const HistoryNameModal = ({ title, listName, onNameChange, onClose, saveHistory }) => {
  return <Modal title={title} onClose={onClose}>
    <div className="my-5 mr-5 ml-5 flex justify-center">
      <div className="w-full">
        <div className="w-full">
          <input type="text" value={listName} onChange={onNameChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darke" placeholder="Enter name" />
        </div>
      </div>
    </div>
    <div className="flex justify-end pt-2 space-x-14">
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-8" onClick={saveHistory}>Save</button>
    </div>
  </Modal>
}

const HistoryListModal = ({ title, onClose, openHistory }) => {

  const [list, setList] = React.useState([]);
  const [displayList, setDisplayList] = React.useState([]);
  const [searchKey, setSearchKey] = React.useState('');
  React.useEffect(() => {
    labelList.getLabelList()
      .then(response => {
        setList(response.data)
        setDisplayList(response.data)
      })
  }, [])

  const handleSearch = (e) => {
    e.preventDefault();
    let _list = [...list];
    _list = list.filter((history) => {
      return history.name.toLowerCase().includes(e.target.value.toLowerCase())
    })
    setSearchKey(e.target.value);
    setDisplayList(_list);
  }

  const handleOpen = (name) => {
    let history = list.find((history) => {
      return history.name === name
    })
    openHistory(history);
  }

  const handleRemove = (name) => {

    let confirmName = window.prompt(`Please input the List Name exactly as [${name}]`);
    if (confirmName !== name)
      return;


    labelList.deleteLabelList(name).then(() => {
      let _list = [...list];
      _list = list.filter((history) => {
        return history.name !== name
      })
      setList(_list);
      _list = _list.filter((history) => {
        return history.name.toLowerCase().includes(searchKey.toLowerCase())
      })
      setDisplayList(_list);
    }).catch(err => console.log)

  }

  return <Modal title={title} onClose={onClose}>

    <div className="bg-white rounded w-full">
      <div className="mb-4">

        <div className="flex mt-4">
          <input onChange={handleSearch} className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker" placeholder="Search" />
        </div>
      </div>
      <div>
        {
          displayList.map((history) => <div key={history.name} className="flex mb-4 items-center">
            <p className="w-full font-black text-grey-darkest">{history.name}
              {/* <span className="inline-block rounded-full text-white bg-purple-500 px-2 py-1 text-xs font-bold mr-3">{moment(history.created_at).fromNow(true)}</span> */}
            </p>
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded ml-8" onClick={() => handleOpen(history.name)}>Open</button>
            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded ml-8" onClick={() => handleRemove(history.name)} >Remove</button>
          </div>)
        }

      </div>
    </div>

  </Modal>
}

const CheckLabel = () => {
  const [mapping, setMapping] = React.useState([{}]);
  const [query, setQuery] = React.useState('');
  const [history, setHistory] = React.useState([]);
  const [foundHistory, setFoundHistory] = React.useState([]);
  const [notFoundHistory, setNotFoundHistory] = React.useState([]);
  const [scannedQuery, setScannedQuery] = React.useState('');
  const [isBlurSearch, setIsBlurSearch] = React.useState(false);
  const [isCaseInSensitive, setIsCaseInSensitive] = React.useState(false);
  const [hasSoundEffect, setHasSoundEffect] = React.useState(true);
  const [listName, setListName] = React.useState('');
  const [showListNameModal, setShowListNameModal] = React.useState(false);
  const [showHistoryNameModal, setShowHistoryNameModal] = React.useState(false);
  const [isSaved, setIsSaved] = React.useState(false);
  const queryInput = React.useRef(null);


  const handleAddMapping = () => {
    setMapping([...mapping, {}])
  }

  const handleRemoveMapping = (index) => {
    setMapping(mapping.slice(index, 1))
  }

  const handleInputChange = (e, index) => {

    const { name, value, checked, type } = e.target;

    const ms = [...mapping];

    ms[index][name] = type === 'checkbox' ? checked : value;

    setMapping(ms);
  }

  const handleSearch = () => {
    console.log(query, mapping);

    const found = mapping.find(m => filterSearch(m.label, query));

    if (found) {
      setFoundHistory([query, ...foundHistory]);
      hasSoundEffect && soundOk();
    } else {
      setNotFoundHistory([query, ...notFoundHistory]);
      hasSoundEffect && soundNotOk();
    }

    setHistory([query, ...history]);
    setScannedQuery(query);
    setMapping(mapping.map(m => m === found ? { ...m, received_qty: (m.received_qty || 0) + 1 } : m))
    setQuery('');
    queryInputFocus();
  }

  const filterSearch = (label, q) => {
    if (isCaseInSensitive) {
      label = label.toLowerCase();
      q = q.toLowerCase();
    }

    return isBlurSearch ? (q.indexOf(label) !== -1) : (label === q)
  }

  const handleOnPaste = (e, index, col) => {
    e.preventDefault();
    const data = e.clipboardData.getData('Text');
    const rows = data.split(/\r?\n/g)
    const ms = [...mapping];
    const table = rows.map((row) => {
      return row.split('\t');
    })

    table.forEach((row, i) => {
      if (!ms[i + index]) {
        ms.push({});
      }
      if (col === 0) {
        ms[i + index].label = row[0] || '';
        ms[i + index].marking = row[1] || ms[i + index].marking;
        ms[i + index].qty = row[2] || ms[i + index].qty;
      } else if (col === 1) {
        ms[i + index].marking = row[0] || '';
        ms[i + index].qty = row[1] || ms[i + index].qty;
      } else if (col === 2) {
        ms[i + index].qty = row[0] || '';
      }
    })

    setMapping(ms)
  }

  const queryInputFocus = () => {
    if (queryInput && queryInput.current) {
      queryInput.current.focus();
      queryInput.current.select();
    }
  }

  React.useEffect(() => {
    const escListener = (e) => {
      if (e.key === 'Escape') {
        queryInputFocus()
      }
    }

    window.addEventListener('keydown', escListener);

    return () => {
      window.removeEventListener('keydown', escListener);
    }
  }, [])

  const handleSaveHistory = () => {
    if (listName === '') {
      return;
    }

    // let list = localStorage.getItem('tools_mapping_history') || '[]';
    // list = JSON.parse(list);
    // let item = list.find((item) => item.name === listName) || {}
    // list = list.filter((_item) => _item.name !== item.name);
    let item = {
      name: listName,
      data: {
        mapping,
        history,
        foundHistory,
        notFoundHistory,
      },
      time: new Date()
    }

    labelList.saveOrUpdateLabelList(item);
    // list.push(item);
    // localStorage.tools_mapping_history = JSON.stringify(list);
    setShowListNameModal(false);
    setIsSaved(true);
  }

  const handleExport = () => {
    const values = mapping.map(m => ({
      'Lookup Label': m.label,
      'Assign Pallet Marking': m.marking,
      'Qty': m.qty,
      'Received Qty': m.received_qty || '',
    }))

    const csv = parse(values);

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    saveAs(blob, "checklabels.csv");
  }

  React.useEffect(() => {
    if (isSaved) {
      handleSaveHistory()
    }
  }, [mapping, history, foundHistory, notFoundHistory])

  const handleOpenHistory = (item) => {
    setListName(item.name)
    setShowHistoryNameModal(false);
    setHistory(item.data.history);
    setMapping(item.data.mapping);
    setFoundHistory(item.data.foundHistory);
    setNotFoundHistory(item.data.notFoundHistory);

  }

  const foundRow = React.useMemo(() => {
    return mapping.find(m => filterSearch(m.label, scannedQuery))
  }, [mapping, scannedQuery]);

  return (
    <div className="p-5">
      <div>
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => window.location.reload()}>Restart</button>
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-8" onClick={() => setShowListNameModal(true)}>Save Current List</button>
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-8" onClick={() => setShowHistoryNameModal(true)}>Open Saved List</button>
      </div>
      <hr className="mt-5 mb-5" />
      <div >
        <div className="min-w-full">
          <strong className="mr-3">Search:</strong>
          <input
            ref={queryInput}
            autoFocus
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={query}
            onChange={e => setQuery(e.target.value)}
            onKeyPress={e => e.key === 'Enter' && handleSearch()}
          />
        </div>
        <div className="mt-1">
          <label htmlFor="check-label-has-sound"><input id="check-label-has-sound" type="checkbox" checked={hasSoundEffect} onChange={e => setHasSoundEffect(e.target.checked)} /> Has Sound Effect</label >
          <label className="ml-3" htmlFor="check-label-blur-search"><input id="check-label-blur-search" type="checkbox" checked={isBlurSearch} onChange={e => setIsBlurSearch(e.target.checked)} /> Blur Search</label >
          <label className="ml-3" htmlFor="check-label-case-in-sensitive"><input id="check-label-in-case-sensitive" type="checkbox" checked={isCaseInSensitive} onChange={e => setIsCaseInSensitive(e.target.checked)} /> Case InSensitive</label >
        </div>
        <div className="text-xs mt-1">Press ESC to focus on input.</div>
      </div>
      <hr className="mt-5 mb-5" />
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-2">
          <h5 className="font-bold text-lg mb-3">Mapping <button onClick={handleExport} className="bg-blue-500 hover:bg-blue-700 text-sm text-white font-bold py-1 px-2 rounded ml-3">Export</button></h5>
          <Table
            columns={columns}
            data={mapping}
            state={{
              handleAddMapping,
              handleRemoveMapping,
              handleInputChange,
              handleOnPaste,
            }}
            getRowProps={({ original }) => original == foundRow ? ({ className: 'border-4 border-blue-600' }) : {}}
          />
        </div>
        <div>
          <div>
            <h5 className="font-bold text-lg mb-3">Marking</h5>
            <div className="border rounded min-w-full h-24 min-h-full bg-white shadow ">
              {scannedQuery && <div className="text-center text-5xl font-bold leading-normal">{foundRow ? foundRow.marking : 'N/A'}</div>}
            </div>
          </div>
          <div className="mt-5">
            <div className="mt-5">
              <History title="All Scanned History" history={history} />
            </div>
          </div>

          <div className="mt-5">
            <div className="mt-5">
              <History title="Not Found History" history={notFoundHistory} />
            </div>
          </div>

          <div className="mt-5">
            <History title="Found History" history={foundHistory} />
          </div>
        </div>
      </div >
      {showListNameModal && <HistoryNameModal title={'List Name'} onNameChange={(e) => setListName(e.target.value)} onClose={() => setShowListNameModal(false)} saveHistory={handleSaveHistory}></HistoryNameModal>}
      {showHistoryNameModal && <HistoryListModal title={'History'} onClose={() => setShowHistoryNameModal(false)} openHistory={handleOpenHistory}></HistoryListModal>}
    </div>

  );
}

const History = ({ title, history }) => {
  return <>
    <h5 className="font-bold text-lg mb-3">{title}</h5>
    <ol className="list-disc list-inside max-h-48 overflow-scroll	">
      {history.map((h, index) => <li key={index}>{h}</li>)}
    </ol>
  </>
}

export default CheckLabel;
