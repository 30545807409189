
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { LOGIN } from '../store/authStore/actions';


const Login = () => {

  const { error } = useSelector((state) => state.authReducer)

  const [form, setForm] = useState({
    username: '',
    password: ''
  });
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch({ type: LOGIN, payload: form })
  }

  const onPropertyChange = (e) => {
    setForm(Object.assign({}, form, { [e.target.name]: e.target.value }));
  }

  return <div className="flex items-center justify-center">
    <div className="w-full max-w-md">
      <form className="bg-white shadow-lg rounded px-12 pt-6 pb-8 mb-4" onSubmit={handleSubmit}>

        <div
          className="text-gray-800 text-2xl flex justify-center border-b-2 py-2 mb-4"
        >
          Login
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-normal mb-2"
            forHtml="username"
          >
            Email
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="username"
            type="email"
            required
            autoFocus
            placeholder="Email"
            value={form.username}
            onChange={onPropertyChange}
          />
        </div>
        <div className="mb-6">
          <label
            className="block text-gray-700 text-sm font-normal mb-2"
            forHtml="password"
          >
            Password
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            type="password"
            placeholder="Password"
            name="password"
            required
            value={form.password}
            onChange={onPropertyChange}
          />
        </div>
        <div className="flex items-center justifyclass-between">
          <button className="px-4 py-2 rounded text-white inline-block shadow-lg bg-blue-500 hover:bg-blue-600 focus:bg-blue-700" type="submit">Sign In</button>
        </div>
      </form>
      {error && <div className="block text-sm text-left text-red-600 bg-red-200 border border-red-400 h-12 flex items-center p-4 rounded-sm" role="alert">
        {error}
      </div>}
    </div>
  </div>
}

export default Login;

