import React from 'react';
import PropTypes from 'prop-types';
import { useTable, useExpanded, useRowSelect } from 'react-table'


const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input
          type="chekbox"
          inputProps={{ ...rest }} />
      </>
    )
  }
)


// Create a default prop getter
const defaultPropGetter = () => ({})
const defaultGetSubRows = (row) => row.subRows || []
const defaultTrueFunc = () => true;

const Table = ({
  columns,
  data,
  footerable = false,
  selection = false,
  onSelectedChange,
  showRowSelection = defaultTrueFunc,
  getFooterProps = defaultPropGetter,
  getSubRows = defaultGetSubRows,
  getRowProps = defaultPropGetter,
  state = {},
}) => {

  const useTables = useTable(
    {
      columns,
      data,
      getSubRows,
      useControlledState: s => {
        return React.useMemo(
          () => ({
            ...s,
            ...state,
          }),
          [s, state]
        )
      },
    },
    useExpanded,
    useRowSelect,
    hooks => {
      selection && hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: (values) => showRowSelection(values) ? <div>
            <IndeterminateCheckbox {...values.row.getToggleRowSelectedProps()} />
          </div> : null,
        },
        ...columns,
      ])
    }
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { expanded },
  } = useTables;

  React.useEffect(() => {
    onSelectedChange && onSelectedChange(selectedFlatRows.map(row => row.original));
  }, [onSelectedChange, selectedFlatRows]);


  return <>
    <table className="border border-collapse min-w-full" {...getTableProps()}>
      <thead className="bg-gray-50">
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()} >
            {headerGroup.headers.map(column => (
              <th
                className="border"
                {...column.getHeaderProps()}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className="bg-white" {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row)
          return (
            // Merge user row props in
            <tr {...row.getRowProps(getRowProps(row))}>
              {row.cells.map(cell => {
                return (
                  <td
                    className="border"
                    {...cell.getCellProps()}
                  >
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
      {footerable && <tfoot>
        {footerGroups.map(group => (
          <tr {...group.getFooterGroupProps()}>
            {group.headers.map(column => (
              <td className="border" {...column.getFooterProps(getFooterProps(column))}>{column.render('Footer')}</td>
            ))}
          </tr>
        ))}
      </tfoot>}
    </table>
  </>
}

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
}

export default Table;
