import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';
import Sidebar from './components/Sidebar';

import Login from './components/Login';
import { useDispatch, useSelector } from 'react-redux';
import { LOAD_USER } from './store/authStore';


const App = () => {

  const { isAuth } = useSelector((state) => state.authReducer)
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: LOAD_USER });
  }, [])

  return <>
    {isAuth ? <BrowserRouter>
      <div className="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-gray-50 text-gray-800">
        <Sidebar />
        <div style={{ marginLeft: '255px' }} className="p-5">
          <Routes />
        </div>
      </div>
    </BrowserRouter> : <Login></Login>}
  </>
}

export default App;
