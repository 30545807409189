import axios from './request';

const login = (params = {}) => axios.post('/api/login', params)
const logout = () => axios.post('/api/logout')
const getUser = () => axios.get('/api/user')
export const user = {
  login,
  logout,
  getUser
}
