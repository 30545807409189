import React from 'react';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import Table from '../ui/Table';


const columns = [
  {
    Header: 'Original Tracking Number',
    accessor: 'original',
    Cell: ({ state: { handleInputChange, handleOnPaste }, row: { index, original }, }) => <input
      className="min-w-full"
      value={original.original || ''}
      name="original"
      onChange={e => handleInputChange(e, index)}
      onPaste={(e) => handleOnPaste(e, index, 0)}
    />,
  },
  {
    Header: 'Need To Replace',
    accessor: 'replace',
    Cell: ({ state: { handleInputChange, handleOnPaste }, row: { index, original }, }) => <input
      className="min-w-full"
      value={original.replace || ''}
      name="replace"
      onChange={e => handleInputChange(e, index)}
      onPaste={(e) => handleOnPaste(e, index, 1)}
    />,
  },
  {
    Header: 'PDF Page',
    accessor: 'page',
    Cell: ({ state: { handleInputChange, handleOnPaste }, row: { index, original }, }) => <input
      className="min-w-full"
      value={original.page || ''}
      name="page"
      onChange={e => handleInputChange(e, index)}
      onPaste={(e) => handleOnPaste(e, index, 2)}
    />,
  },
  {
    Header: ({ state: { handleAddMapping } }) => <div className="cursor-pointer" onClick={handleAddMapping}>+</div>,
    accessor: 'add',
    Cell: ({ state: { handleRemoveMapping }, row: { index } }) => <div className="cursor-pointer text-center" onClick={(e) => handleRemoveMapping(e, index)}>-</div>,
  },
];

const SwitchLabel = () => {
  const [mapping, setMapping] = React.useState([{}]);
  const [totalPage, setTotalPage] = React.useState(null);
  const [images, setImages] = React.useState([]);
  const [processing, setProcessing] = React.useState(false);
  const [query, setQuery] = React.useState('');
  const [history, setHistory] = React.useState([]);

  const queryInput = React.useRef(null);

  const handleAddMapping = () => {
    setMapping([...mapping, {}])
  }

  const handleRemoveMapping = (index) => {
    setMapping(mapping.slice(index, 1))
  }

  const handleInputChange = (e, index) => {
    const { name, value, checked, type } = e.target;

    const ms = [...mapping];

    ms[index][name] = type === 'checkbox' ? checked : value;

    setMapping(ms);
  }

  const readFileData = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve(e.target.result);
      };
      reader.onerror = (err) => {
        reject(err);
      };
      reader.readAsDataURL(file);
    });
  };

  //param: file -> the input file (e.g. event.target.files[0])
  //return: images -> an array of images encoded in base64
  const convertPdfToImages = async (file) => {
    const imgs = [];
    const data = await readFileData(file);
    const pdf = await pdfjsLib.getDocument(data).promise;
    setTotalPage(pdf.numPages);
    const canvas = document.createElement("canvas");
    for (let i = 0; i < pdf.numPages; i++) {
      const page = await pdf.getPage(i + 1);
      const viewport = page.getViewport({ scale: 1 });
      const context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      await page.render({ canvasContext: context, viewport: viewport }).promise;
      imgs.push(canvas.toDataURL());
    }
    canvas.remove();
    return imgs;
  }

  const handleUploadFile = async (e) => {
    setProcessing(true);

    const file = e.target.files[0];
    const imgs = await convertPdfToImages(file);

    setImages(imgs);

    setProcessing(false);
  }

  const handleSearch = () => {
    setHistory([query, ...history]);

    const m = mapping.find(m => m.original == query);
    if (!m) {
      alert('Input tracking number is not found.');
      return;
    }

    const { page } = m;

    if (!page) {
      alert('Page not found');
      return;
    }

    if (page > images.length) {
      alert(`Page ${page} is beyond the total page (${images.length}) of the PDF. `);
      return;
    }

    var img = new Image();
    img.src = images[page - 1];
    img.style.width = '100%';

    const imgWindow = window.open('');
    imgWindow.document.body.appendChild(img);
    imgWindow.document.close();
    img.onload = () => {
      imgWindow.focus();
      imgWindow.print();
      setTimeout(() => imgWindow.close(), 1000);
    };
  }

  const handleOnPaste = (e, index, col) => {
    e.preventDefault();
    const data = e.clipboardData.getData('Text');
    const rows = data.split(/\r?\n/g)
    const ms = [...mapping];
    const table = rows.map((row) => {
      return row.split('\t');
    })

    table.forEach((row, i) => {
      if (!ms[i + index]) {
        ms.push({});
      }
      if (col === 0) {
        ms[i + index].original = row[0] || '';
        ms[i + index].replace = row[1] || ms[i + index].replace;
        ms[i + index].page = row[2] || ms[i + index].page;
      } else if (col === 1) {
        ms[i + index].replace = row[0] || '';
        ms[i + index].page = row[1] || ms[i + index].page;
      } else if (col === 2) {
        ms[i + index].page = row[0] || '';
      }
    })

    setMapping(ms)
  }

  React.useEffect(() => {
    const escListener = (e) => {
      if (e.key === 'Escape') {
        if (queryInput && queryInput.current) {
          queryInput.current.focus();
          queryInput.current.select();
        }
      }
    }


    window.addEventListener('keydown', escListener);

    return () => {
      window.removeEventListener('keydown', escListener);
    }
  }, [])

  const isDone = totalPage === images.length;

  return (
    <div className="p-5">
      <div>
        <input type="file" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onChange={handleUploadFile} />
        <button className="ml-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => window.location.reload()}>Restart</button>
      </div>
      <hr className="mt-5 mb-5" />
      {processing && <div>Processing...</div>}
      <div>
        {totalPage !== null && <p>
          <strong>Total Page: {totalPage}</strong>
        </p>}
        {isDone && <>
          <p className="flex items-center">
            <strong className="mr-3">Search:</strong>
            <input
              ref={queryInput}
              autoFocus
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={query}
              onChange={e => setQuery(e.target.value)}
              onKeyPress={e => e.key === 'Enter' && handleSearch()}
            />
          </p>
        </>}
      </div>
      <hr className="mt-5 mb-5" />
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-2">
          <h5 className="font-bold text-lg mb-3">Mapping</h5>
          <Table
            columns={columns}
            data={mapping}
            state={{
              handleAddMapping,
              handleRemoveMapping,
              handleInputChange,
              handleOnPaste,
            }}
          />
        </div>
        <div>
          <h5 className="font-bold text-lg mb-3">Scanned History</h5>
          <ol className="list-disc list-inside">
            {history.map((h, index) => <li key={index}>{h}</li>)}
          </ol>
        </div>
      </div>
    </div>

  );
}

export default SwitchLabel;
