import React from 'react';
import { Switch, Route } from 'react-router-dom';
import SwitchLabel from './components/SwitchLabel';
import CheckLabel from './components/CheckLabel';
import OneToX from './components/OneToX';
import OneToX2 from './components/OneToX2';
import Home from './components/Home';

const Routes = () => {

  return <Switch>
    <Route path="/labels/switch">
      <SwitchLabel />
    </Route>
    <Route path="/labels/check">
      <CheckLabel />
    </Route>
    <Route path="/labels/1tox">
      <OneToX />
    </Route>
    <Route path="/labels/1tox2">
      <OneToX2 />
    </Route>
    <Route path="/">
      <Home />
    </Route>
  </Switch>
}

export default Routes;
