import * as actions from './actions';
const initialState = {
  user: null,
  isAuth: false,
  error: null,
};
export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      window.localStorage.setItem(process.env.REACT_APP_TOKEN_KEY || 'tools-token', action.data.token);
      state = Object.assign({}, state, { user: action.data.user, isAuth: true, error: null })
      return state

    case actions.LOGIN_ERROR:
      return Object.assign({}, state, { error: action.error })

    case actions.LOAD_USER_SUCCESS:
      return Object.assign({}, state, { user: action.data, isAuth: true, error: null })

    case actions.LOAD_USER_ERROR:
      return Object.assign({}, state, { user: null, isAuth: false, error: null })

    case actions.LOGOUT_SUCCESS:
      window.localStorage.removeItem(process.env.REACT_APP_TOKEN_KEY || 'tools-token');
      return Object.assign({}, state, { user: null, isAuth: false, error: null })
    default:
      return state
  }
};
