import React from "react";
import VirtualizedTable from "../ui/VirtualizedTable";
import { parse } from "json2csv";
import { saveAs } from "file-saver";
import { sumBy } from "lodash";
import { onetox } from "../../services";
import printJS from "print-js";
import { soundNotOk } from "../../helpers";

const columns = [
  {
    Header: "Original Tracking Number",
    accessor: "original",
    Cell: ({
      state: { handleInputChange, handleOnPaste },
      row: { index, original },
    }) => (
      <input
        className="min-w-full"
        value={original.original || ""}
        name="original"
        onChange={(e) => handleInputChange(e, index)}
        onPaste={(e) => handleOnPaste(e, index, 0)}
      />
    ),
  },
  {
    Header: "Need To Replace",
    accessor: "replace",
    Cell: ({
      state: { handleInputChange, handleOnPaste },
      row: { index, original },
    }) => (
      <input
        className="min-w-full"
        value={original.replace || ""}
        name="replace"
        onChange={(e) => handleInputChange(e, index)}
        onPaste={(e) => handleOnPaste(e, index, 1)}
      />
    ),
  },
  {
    Header: "Qty",
    accessor: "qty",
    Cell: ({
      state: { handleInputChange, handleOnPaste },
      row: { index, original },
    }) => (
      <input
        className="min-w-full"
        value={original.qty || ""}
        name="qty"
        onChange={(e) => handleInputChange(e, index)}
        onPaste={(e) => handleOnPaste(e, index, 2)}
      />
    ),
  },
  {
    Header: "PDF Page From",
    accessor: "page_from",
    Cell: ({
      state: { handleInputChange, handleOnPaste },
      row: { index, original },
    }) => (
      <input
        className="min-w-full"
        value={original.page_from || ""}
        name="page_from"
        onChange={(e) => handleInputChange(e, index)}
        onPaste={(e) => handleOnPaste(e, index, 3)}
      />
    ),
  },
  {
    Header: "PDF Page To",
    accessor: "page_to",
    Cell: ({
      state: { handleInputChange, handleOnPaste },
      row: { index, original },
    }) => (
      <input
        className="min-w-full"
        value={original.page_to || ""}
        name="page_to"
        onChange={(e) => handleInputChange(e, index)}
        onPaste={(e) => handleOnPaste(e, index, 4)}
      />
    ),
  },
  {
    Header: "Current Page",
    accessor: "page",
    Cell: ({
      state: { handleInputChange, handleOnPaste },
      row: { index, original },
    }) => (
      <input
        className="min-w-full"
        value={original.page || ""}
        name="page"
        onChange={(e) => handleInputChange(e, index)}
        // onPaste={(e) => handleOnPaste(e, index, 5)}
        disabled
      />
    ),
  },
  {
    Header: "Qty Labeled",
    accessor: "labeled_qty",
    Cell: ({
      state: { handleInputChange, handleOnPaste },
      row: { index, original },
    }) => (
      <input
        className="min-w-full"
        value={original.labeled_qty || ""}
        name="labeled_qty"
        onChange={(e) => handleInputChange(e, index)}
        onPaste={(e) => handleOnPaste(e, index, 6)}
      />
    ),
  },
  {
    Header: ({ state: { handleAddMapping } }) => (
      <div className="cursor-pointer" onClick={handleAddMapping}>
        +
      </div>
    ),
    accessor: "add",
    Cell: ({ state: { handleRemoveMapping }, row: { index } }) => (
      <div
        className="cursor-pointer text-center"
        onClick={(e) => handleRemoveMapping(index, e)}
      >
        -
      </div>
    ),
  },
];

const DB_FILE_ID_KEY = "on2x-fileid";
const DB_MAPPING_KEY = "on2x-mapping";
const DB_HISTORY_KEY = "on2x-history";
const DB_LAST_EDIT_AT = "on2x-lasteditat";
const DB_DUPLICATE_COUNT = "on2x-duplicate-count";

const DB_KEYS = [
  DB_FILE_ID_KEY,
  DB_MAPPING_KEY,
  DB_HISTORY_KEY,
  DB_LAST_EDIT_AT,
  DB_DUPLICATE_COUNT,
];

const saveToLocalDB = (key, content) => {
  window.localStorage.setItem(key, JSON.stringify(content));
  window.localStorage.setItem(
    DB_LAST_EDIT_AT,
    JSON.stringify(new Date().toLocaleString())
  );
};

const getFromLocalDB = (key) => {
  const content = window.localStorage.getItem(key);
  if (content) {
    return JSON.parse(content);
  }
  return null;
};

const OneToX = () => {
  const [mapping, setMapping] = React.useState(
    getFromLocalDB(DB_MAPPING_KEY) || [{}]
  );
  React.useEffect(() => {
    saveToLocalDB(DB_MAPPING_KEY, mapping);
  }, [mapping]);

  const [history, setHistory] = React.useState(
    getFromLocalDB(DB_HISTORY_KEY) || []
  );
  React.useEffect(() => {
    saveToLocalDB(DB_HISTORY_KEY, history);
  }, [history]);

  const [fileId, setFileId] = React.useState(
    getFromLocalDB(DB_FILE_ID_KEY) || null
  );
  React.useEffect(() => {
    saveToLocalDB(DB_FILE_ID_KEY, fileId);
  }, [fileId]);

  const [duplicateCount, setDuplicateCount] = React.useState(
    getFromLocalDB(DB_DUPLICATE_COUNT) || 1
  );
  React.useEffect(() => {
    saveToLocalDB(DB_DUPLICATE_COUNT, duplicateCount);
  }, [duplicateCount]);

  const [loading, setLoading] = React.useState(false);
  const [query, setQuery] = React.useState("");

  const queryInput = React.useRef(null);

  const handleAddMapping = () => {
    setMapping([...mapping, {}]);
  };

  const handleRemoveMapping = (index) => {
    if (!window.confirm('are you sure to delete?')) {
      return
    }
    const m = [...mapping];
    m.splice(index, 1)
    setMapping(m);
  };

  const handleInputChange = (e, index) => {
    const { name, value, checked, type } = e.target;

    const ms = [...mapping];

    ms[index][name] = type === "checkbox" ? checked : value;

    if (name === "labeled_qty" && ms[index]["page_from"]) {
      ms[index]["page"] =
        parseInt(ms[index]["page_from"]) + parseInt(value) - 1;
    }

    setMapping(ms);
  };

  const handleUploadFile = async (e) => {
    setLoading(true);

    const response = await onetox.uploadFile(e.target.files[0]);

    setFileId(response.data.fileId);

    setLoading(false);
  };

  const handleSearch = async () => {
    setLoading(true);
    setHistory([query, ...history]);

    const m = mapping.find((m) => m.original == query);
    if (!m) {
      setLoading(false);
      soundNotOk();
      alert("Input tracking number is not found.");
      return;
    }

    const { page, qty, labeled_qty, original, page_from } = m;

    const printPage = page ? parseInt(page) + 1 : page_from;

    if (parseInt(qty) <= parseInt(labeled_qty)) {
      alert(`All labels about ${original} had been printed.`);
      setLoading(false);
      return;
    }

    const response = await onetox.printPage(fileId, printPage, duplicateCount);

    const fileURL = URL.createObjectURL(response.data);
    printJS(fileURL);

    handleLabeled(m);
    queryInputFocus();
    setLoading(false);
  };

  const handleLabeled = (m) => {
    const index = mapping.findIndex((_m) => _m == m);

    handleInputChange(
      { target: { value: (m.labeled_qty || 0) + 1, name: "labeled_qty" } },
      index
    );
  };

  const handleOnPaste = (e, index, col) => {
    e.preventDefault();
    const data = e.clipboardData.getData("Text");
    const rows = data.split(/\r?\n/g);
    const ms = [...mapping];
    const table = rows.map((row) => {
      return row.split("\t");
    });

    const fields = [
      "original",
      "replace",
      "qty",
      "page_from",
      "page_to",
      "page",
      "labeled_qty",
    ];

    table.forEach((row, i) => {
      if (!ms[i + index]) {
        ms[i + index] = {};
      }

      for (let j = 0; j < fields.length - col; j++) {
        if (row[j]) {
          ms[i + index][fields[j + col]] = row[j];
        }
      }
    });

    setMapping(ms);
  };

  const handleExport = () => {
    const values = mapping.map((m) => ({
      "Original Tracking Number	": m.original,
      "Need To Replace": m.replace,
      Qty: m.qty,
      "PDF Page From": m.page_from || "",
      "PDF Page To": m.page_to || "",
      "Current Page": m.page || "",
      "Qty Labeled": m.labeled_qty || "",
    }));

    const csv = parse(values);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    saveAs(blob, "OneToX.csv");
  };

  const handleRestart = () => {
    if (window.confirm("All data will be cleared. Are you sure to restart?")) {
      !!fileId && mapping.length > 0 && handleExport();
      DB_KEYS.forEach((k) => localStorage.removeItem(k));
      window.location.reload();
    }
  };

  const queryInputFocus = () => {
    if (queryInput && queryInput.current) {
      queryInput.current.focus();
      queryInput.current.select();
    }
  };

  React.useEffect(() => {
    const escListener = (e) => {
      if (e.key === "Escape") {
        queryInputFocus();
      }
    };

    window.addEventListener("keydown", escListener);

    return () => {
      window.removeEventListener("keydown", escListener);
    };
  }, []);

  const totalQty = React.useMemo(
    () => sumBy(mapping, (m) => parseFloat(m.qty || 0)),
    [mapping]
  );
  const scannedQty = React.useMemo(
    () => sumBy(mapping, (m) => parseFloat(m.labeled_qty || 0)),
    [mapping]
  );
  const remainingQty = React.useMemo(
    () => (totalQty || 0) - (scannedQty || 0),
    [totalQty, scannedQty]
  );

  return (
    <div className="p-5">
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-2">
          <div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label>Duplicate PDF Count: </label>
                <input
                  type="number"
                  step="1"
                  min="1"
                  value={duplicateCount}
                  onChange={(e) => setDuplicateCount(e.target.value)}
                  className="border focus:ring-indigo-500 focus:border-indigo-500 block  px-3 py-1 border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="mt-3">
              {!fileId && (
                <input
                  type="file"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  onChange={handleUploadFile}
                />
              )}
            </div>
            <div className="mt-3">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={handleRestart}
              >
                Restart
              </button>
            </div>
            {loading && <div className="mt-3">Loading...</div>}
          </div>
        </div>
        <div>
          <div className="font-7xl font-bold">Total Qty: {totalQty}</div>
          <div className="font-7xl font-bold mt-3">
            Scanned Qty: {scannedQty}
          </div>
          <div className="font-7xl font-bold mt-3">
            Remaining Qty: {remainingQty}
          </div>
          <div className="font-7xl font-bold mt-3">
            Last Edit At: {getFromLocalDB(DB_LAST_EDIT_AT)}
          </div>
        </div>
      </div>

      <hr className="mt-5 mb-5" />

      {!!fileId && (
        <div>
          <p className="flex items-center">
            <strong className="mr-3">Search:</strong>
            <input
              ref={queryInput}
              autoFocus
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyPress={(e) => e.key === "Enter" && handleSearch()}
            />
          </p>
        </div>
      )}
      <hr className="mt-5 mb-5" />
      <div className="">
        <h5 className="font-bold text-lg mb-3">
          Mapping{" "}
          <button
            onClick={handleExport}
            className="bg-blue-500 hover:bg-blue-700 text-sm text-white font-bold py-1 px-2 rounded ml-3"
          >
            Export
          </button>
        </h5>
        <VirtualizedTable
          columns={columns}
          data={mapping}
          state={{
            handleAddMapping,
            handleRemoveMapping,
            handleInputChange,
            handleOnPaste,
          }}
        />
      </div>
      <div className="mt-10">
        <h5 className="font-bold text-lg mb-3">Scanned History</h5>
        <ol className="list-disc list-inside">
          {history.map((h, index) => (
            <li key={index}>{h}</li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default OneToX;
