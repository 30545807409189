import axios from './request';


const deleteLabelList = (name) => axios.delete(`/api/labelLists/${name}`)
const saveOrUpdateLabelList = (params) => axios.post('/api/labelLists', params)
const getLabelList = () => axios.get('/api/labelLists')
export const labelList = {
  deleteLabelList,
  saveOrUpdateLabelList,
  getLabelList
}
