import {
  createStore, applyMiddleware, combineReducers,
} from 'redux';
import { authReducer, authSage } from './authStore';
import createSagaMiddleware from 'redux-saga';

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  authReducer,
});

const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(authSage)
export default store;
