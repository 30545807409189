import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { user } from '../../services';

function* login(action) {
  try {
    const response = yield call(user.login, action.payload);
    yield put({ type: actions.LOGIN_SUCCESS, data: response.data });
  } catch (e) {
    yield put({ type: actions.LOGIN_ERROR, error: 'Login Failed' });
  }
}

function* loadUser() {
  try {
    const response = yield call(user.getUser);
    yield put({ type: actions.LOAD_USER_SUCCESS, data: response.data });
  } catch (e) {
    yield put({ type: actions.LOAD_USER_ERROR, error: e.message });
  }
}

function* logout() {
  try {
    yield call(user.logout);
    yield put({ type: actions.LOGOUT_SUCCESS });
  } catch (e) {
    yield put({ type: actions.LOGIN_ERROR, error: e });
  }
}
// watcher Saga
export function* authSage() {
  yield takeLatest(actions.LOGIN, login);
  yield takeLatest(actions.LOGOUT, logout);
  yield takeLatest(actions.LOAD_USER, loadUser);
}

