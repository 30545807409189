import axios from "./request";

const uploadFile = (file) => {
  const formData = new FormData();
  formData.append("file", file);

  return axios.post(`/api/onetox/upload`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const printPage = (fileId, page, duplicateCount) => {
  return axios.post(
    `/api/onetox/printPage`,
    { fileId, page, duplicateCount },
    { responseType: "blob" }
  );
};
export const onetox = {
  uploadFile,
  printPage,
};
