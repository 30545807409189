import * as Tone from 'tone';


export const soundOk = () => {
  const osc = new Tone.Oscillator().toDestination();
  osc.frequency.value = "C8";
  osc.start().stop("+0.5");
}

export const soundNotOk = () => {
  const osc = new Tone.Oscillator().toDestination();
  osc.frequency.value = "D5";
  osc.start().stop("+0.5");

  setTimeout(() => {
    let msg = new SpeechSynthesisUtterance();
    msg.text = "Not Found";
    window.speechSynthesis.speak(msg);
  }, [300]);
}
