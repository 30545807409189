import React from "react";
import PropTypes from "prop-types";
import { useTable, useExpanded, useRowSelect } from "react-table";
import { useVirtual } from "react-virtual";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="chekbox" inputProps={{ ...rest }} />
      </>
    );
  }
);

// Create a default prop getter
const defaultPropGetter = () => ({});
const defaultGetSubRows = (row) => row.subRows || [];
const defaultTrueFunc = () => true;

const Table = ({
  columns,
  data,
  footerable = false,
  selection = false,
  onSelectedChange,
  height = '400px',
  showRowSelection = defaultTrueFunc,
  getFooterProps = defaultPropGetter,
  getSubRows = defaultGetSubRows,
  getRowProps = defaultPropGetter,
  state = {},
}) => {
  const useTables = useTable(
    {
      columns,
      data,
      getSubRows,
      useControlledState: (s) => {
        return React.useMemo(
          () => ({
            ...s,
            ...state,
          }),
          [s, state]
        );
      },
    },
    useExpanded,
    useRowSelect,
    (hooks) => {
      selection &&
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: "selection",
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: (values) =>
              showRowSelection(values) ? (
                <div>
                  <IndeterminateCheckbox
                    {...values.row.getToggleRowSelectedProps()}
                  />
                </div>
              ) : null,
          },
          ...columns,
        ]);
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { expanded },
  } = useTables;

  const tableContainerRef = React.useRef(null);

  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 40,
  });
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;

  React.useEffect(() => {
    onSelectedChange &&
      onSelectedChange(selectedFlatRows.map((row) => row.original));
  }, [onSelectedChange, selectedFlatRows]);

  return (
    <>
      <div
        ref={tableContainerRef}
        style={{ height: height, overflow: "auto" }}
      >
        <table
          className="border border-collapse min-w-full"
          {...getTableProps()}
        >
          <thead className="bg-gray-50 sticky top-0 m-0 bg-white">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th className="border" {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="bg-white" {...getTableBodyProps()}>
            {paddingTop > 0 && (
              <tr>
                <td style={{ height: `${paddingTop}px` }} />
              </tr>
            )}
            {virtualRows.map((row) => {
              row = rows[row.index];
              prepareRow(row);
              return (
                // Merge user row props in
                <tr {...row.getRowProps(getRowProps(row))}>
                  {row.cells.map((cell) => {
                    return (
                      <td className="border" {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {paddingBottom > 0 && (
              <tr>
                <td style={{ height: `${paddingBottom}px` }} />
              </tr>
            )}
          </tbody>
          {footerable && (
            <tfoot>
              {footerGroups.map((group) => (
                <tr {...group.getFooterGroupProps()}>
                  {group.headers.map((column) => (
                    <td
                      className="border"
                      {...column.getFooterProps(getFooterProps(column))}
                    >
                      {column.render("Footer")}
                    </td>
                  ))}
                </tr>
              ))}
            </tfoot>
          )}
        </table>
      </div>
    </>
  );
};

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
};

export default Table;
