import axios from 'axios';
import qs from 'qs';


axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.timeout = 300000; // 300s
// axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_SERVER_API_URL;

// Format nested params correctly
axios.interceptors.request.use(config => {
  config.paramsSerializer = params => {
    // q is already included in the Axios package
    return qs.stringify(params, {
      arrayFormat: "brackets",
      encode: false
    });
  };

  const token = window.localStorage.getItem(process.env.REACT_APP_TOKEN_KEY || 'tools-token');

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }else{
    window.localStorage.removeItem('tools-jwt-token');
  }

  return config;
});

export default axios;
